<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <el-row class="row-btn-group" style="margin-top: 20px">
                <el-button type="primary" @click="handleAdd('新增')">
                    <i class="cw-icon cw-icon-tianjia"></i>新增
                </el-button>
            </el-row>
            <el-table
                    :data="tableData"
                    style="width: 100%"

            >
                <el-table-column
                        label="物料类型"
                        prop="name">
                </el-table-column>
                <el-table-column
                        label="ID"
                        prop="id">
                </el-table-column>
                <el-table-column
                        prop="id"
                        label="操作"
                        fixed="right"
                        width="115">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'edit',row:scope.row}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'delete',row:scope.row}">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <!--//编辑单位-->
        <el-dialog
                width="480px"
                :title="title"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="formDalogVisible"
                :before-close="handleCancel">
            <el-form :model="formData" ref="form" :rules="formRules" label-position="right" label-width="85px">

                <el-form-item prop="name" label="物料类型">
                    <el-input placeholder="物料类型" v-model="formData.name" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label-width="0">
                    <el-button type="primary" @click="handleSubmit" :loading="loading">确 定</el-button>
                    <el-button @click="handleCancel">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-container>

</template>

<script>
import _ from 'lodash'
import request from '@/utils/request'

export default {
    name: "foodUnit",

    data() {
        return {
            loading: false,
            tableData: [],

            title: '新增物料类型',
            formDalogVisible: false,
            formData: {
                id: '',
                name: '',

            },

            formRules: {
                name: [
                    {required: true, message: '请输入物料类型', trigger: 'blur'}
                ],
            },
        }
    },

    methods: {
        async dropdownCommand(command) {//监听下拉框事件
            if ("edit" === command.type) {
                this.formData = _.cloneDeep(command.row)
                this.title = '修改物料类型'
                this.formDalogVisible = true;//打开编辑窗口
            }
            if ("delete" === command.type) {
                try {
                    await this.$confirm('删除后无法恢复，确定删除吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })

                    await request({
                        url: '/platform/food/material/goods/delete',
                        data: {
                            id: command.row.id
                        }
                    })
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getData();
                } catch (e) {
                }
            }
        },


        handleCancel() {
            this.formData = {
              id: '',
              name: '',
            };
            this.$refs.form.resetFields();
            this.formDalogVisible = false;
        },

        handleAdd() {
            this.title = '新增物料类型';
            this.formDalogVisible = true;
        },


        //修改
        async handleSubmit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    try {
                        this.loading = true
                        let url = ''
                        if (this.formData.id) {
                            url = '/platform/food/material/goods/editDo'
                        } else {
                            url = '/platform/food/material/goods/addDo'
                        }
                        let formData = {}
                        for(let key in this.formData){
                            if(this.formData[key]){
                                formData[key]= this.formData[key]
                            }
                        }
                        await request({
                            url: url,
                            method: 'post',
                            data: formData

                        })
                        this.loading = false
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getData();
                        this.handleCancel()

                    } catch (e) {
                        this.loading = false
                    }
                }
            })
        },
        async getData() {
            try {
                let {data} = await request({
                    url: '/platform/food/material/goods/data',
                    method: 'post'
                })
                console.log(data)
                this.tableData = data.list;
            } catch (e) {

            }
        },

    },
    mounted() {
        this.getData();
    }
}
</script>

<style lang="less">
.adsdia {
    .el-upload {
        height: 100px;
        width: 100px !important;
        line-height: 100px;
    }
}

.btn-group {
    button {
        margin-left: 20px;
    }

}

.el-input--prefix .el-input__inner {
    padding-left: 46px;
}

.el-input__prefix {
    left: 15px;
    color: #303133;

    i {
        font-style: normal;
    }
}

.icon-cursor {
    cursor: pointer;
    margin-right: 10px;

    &:hover {
        color: #348EED;
    }
}

.avatar-uploader {
    .el-upload {
        border: 1px solid #DCDFE6;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 72px;
        display: block;

        &:hover {
            border-color: #409EFF;
        }
    }

    .cw-icon-tupian {
        font-size: 24px;
        color: #c0c4cc;
        width: 72px;
        height: 72px;
        line-height: 72px;
        text-align: center;
    }

    .avatar {
        width: 72px;
        height: 72px;
        display: block;
    }
}
</style>
